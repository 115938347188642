[class~="MuiSlider-markLabelActive"]:last-of-type {
  color: red !important;
  font-size: 200px;
}

.MuiDialog-root.MuiSlider-markLabelActive:last-of-type {
  color: red !important;
  font-size: 200px;
}

.MuiDialog-root.MuiSlider-markLabelActive:last-child {
  color: red !important;
  font-size: 200px;
}

body
  > div.MuiDialog-root
  > div.MuiDialog-container.MuiDialog-scrollPaper
  > div
  > div
  > div
  > div:nth-child(1)
  > div
  > div
  > span.MuiSlider-markLabelActive:last-of-type {
  color: red !important;
  font-size: 200px;
}
