html,
head,
body,
.landscape,
.portrait,
#wrapper,
.MuiContainer-root,
#app {
  height: 100%;
}

.portrait {
  padding-left: 5px;
  padding-right: 5px;
}

/* body {
  margin: 7px !important;
} */

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

input::placeholder {
  /* Firefox, Chrome, Opera */
  text-align: center;
}
input[type="email"]::placeholder {
  /* Firefox, Chrome, Opera */
  text-align: left !important;
}
.lessPadding .MuiInputBase-root input::placeholder {
  text-align: "left !important";
  padding: "0px !important";
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
.landscape {
  display: none;
}
.portrait {
  display: block;
}

@media screen and (orientation: landscape) {
  .landscape {
    display: block;
  }
  .portrait {
    display: none;
  }
}
